<template>
  <div class="sub-header-thin-div">
    <v-card :class="isMobile ? 'mx-auto my-10' : 'mx-auto my-20'" :max-width="isMobile ? '95%' : '80%'">
      <br/>
      <v-card-text :class="isMobile ? 'my-1 text-center' : 'my-10 text-center'">
        <span :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">{{ $t('privacy_policy') }}</span>
      </v-card-text>
      <v-card-text class="m-5" :style="isMobile ? 'font-size: 12px;' : 'font-size: 20px;'">
        <div>
          <p>
            Zuständige Behörde im Sinne der Datenschutz-Grundverordnung (DSGVO)
          </p>
        </div>
        <div>
          <h3><b>Benutzerrechte</b></h3>
        </div>
        <div>
          <p>
            Sie haben folgende Rechte:
          </p>
          <ol :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              Eine Bestätigung, ob und inwieweit Ihre personenbezogenen Daten verwendet und verarbeitet werden, sowie den Zugriff auf die über Sie gespeicherten personenbezogenen Daten und zusätzliche Informationen anfordern
            </li>
            <li>
              Eine Kopie der personenbezogenen Daten, die Sie uns freiwillig bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format anfordern
            </li>
            <li>
              Eine Berichtigung der personenbezogenen Daten, die wir von Ihnen gespeichert haben, anfordern
            </li>
            <li>
              Das Löschen Ihrer personenbezogenen Daten beantragen
            </li>
            <li>
              Der Verarbeitung Ihrer personenbezogenen Daten durch uns widersprechen
            </li>
            <li>
              Die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten durch uns beantragen
            </li>
            <li>
              Eine Beschwerde bei einer Aufsichtsbehörde einreichen
            </li>
          </ol>
          <p>
            Beachten Sie jedoch, dass diese Rechte nicht uneingeschränkt gelten, sondern unseren eigenen rechtmäßigen Interessen sowie behördlichen Vorschriften unterliegen.
          </p>
          <p>
            Wenn Sie eines der hier aufgeführten Rechte in Anspruch nehmen möchten oder weitere Informationen wünschen, wenden Sie sich an unseren Datenschutzbeauftragten unter:
          </p>
        </div>
        <div>
          <h3><b>Speicherung</b></h3>
        </div>
        <div>
          <p>
            Nutzungsbedingungen:
          </p>
          <p>
            &#8211; eventbox App &#8211;
          </p>
        </div>
        <div>
          <p>
            <b>Anbieter der App</b>
            <br />
            eventbox („APP“ oder „Service“) ist eine Applikation (App) der eventbox, E-Mail: info@eventboxde.com („eventbox“), die über verschiedene App-Stores heruntergeladen werden kann. eventbox stellt Nutzern die APP zu den nachfolgend genannten Bedingungen zur Verfügung. Diese Nutzungsbedingungen können im Rahmen der APP jederzeit eingesehen werden.
          </p>
          <p>
            <b>Beschreibung des Service</b>
            <br />
            Über die APP können Nutzer Veranstaltungen kennen lernen und Tickets für Veranstaltungen buchen.
            <br />
            Die Nutzung der allgemeinen Angebote der APP ist kostenfrei möglich; für die in der App verzeichneten Veranstaltungen können kostenpflichtige Tickets erworben werden („In-App-Käufe“). Hierauf wird an der betreffenden Stelle eindeutig hingewiesen.
          </p>
          <p>
            <b>Registrierung</b>
            <br />
            Für die Nutzung der Profil-Funktionen sowie für den Kauf von Tickets ist eine Registrierung erforderlich. Hierfür gibt der Nutzer seinen Namen, Geburtsdatum, eine E-Mail-Adresse und einen selbst gewählten Nutzernamen sowie ein Passwort ein und stimmt diesen Nutzungsbedingungen und den Datenschutzbestimmungen zu.
            <br />
            Die Registrierung ist erst abgeschlossen und die entsprechenden Funktionen der APP vollständig nutzbar, wenn der neu registrierte Nutzer den an seine angegebene E-Mail-Adresse versandten Bestätigungs-Link aktiviert. Alternativ ist eine Bestätigung über einen per SMS versendeten Code möglich, hierfür wird die Handynummer benötigt.
            <br />
            Eine Registrierung mittels Verknüpfung zum Facebook-Account ist ebenfalls möglich.
            <br />
            Die App lädt die primären Kontoinformationen der Benutzer auf https://europe-west1-eventbox-c7c8f.cloudfunctions.net
          </p>
          <p>
            <b>In-App-Kauf, Zahlung und Zahlungsmethode</b>
            <br />
            Soweit Leistungen im Rahmen der APP als In-App-Käufe kostenpflichtig angeboten werden, gelten folgende Regelungen:
            <br />
            Die Kosten der In-App-Käufe sind dem jeweils aktuellen Angebot der jeweiligen Veranstaltung zu entnehmen.
            <br />
            Der Bestellvorgang beginnt mit Auswahl des Events und der Menge der hierfür zu kaufenden Tickets über die Buttons + und – an der entsprechenden Ticket-Kategorie. Die Menge der verfügbaren Tickets ist abhängig vom durch den Organizer festgelegten Kontingent.
            <br />
            Das Vertragsangebot erfolgt durch Klicken des Buttons „Buy“ und bezieht sich auf alle entsprechend Nummer 4.2 ausgewählten Tickets, die mit Einzelpreis und Gesamtpreis oberhalb des Buttons „Buy“ angegeben sind.
            <br />
            Der Vertrag wird durch bereitstellen der Tickets im Nutzerkonto des Käufers angenommen.
            <br />
            Die Zahlung erfolgt über die in der App hinterlegten bargeldlosen Zahlungsmöglichkeiten. Welche Zahlungsmöglichkeiten dies sind, ist dem jeweils aktuellen Angebot zu entnehmen.
          </p>
          <p>
            <b>Umfang des dem Nutzer eingeräumten Nutzungsrechts</b>
            <br />
            Der Nutzer erhält das einfache, nicht ausschließliche, nicht übertragbare, widerrufliche Nutzungsrecht, die APP und die darin dargestellten bzw. darüber abrufbaren Inhalte von eventbox oder Dritten (nachfolgend „APP-Content“) gemäß diesen Nutzungsbedingungen zu nutzen, insbesondere die APP über die hierzu vorgesehenen APP Stores auf ein hierfür geeignetes Mobilgerät (z.B. Smartphone, Tablet) herunterzuladen, zu installieren und die APP gemäß diesen Nutzungsbestimmungen – insbesondere gemäß Ziffer 2.1 – zu verwenden.
            <br />
            Im Rahmen der kostenlosen Nutzung der Free-Version der App ist eventbox berechtigt, dieses Nutzungsrecht frei zu widerrufen und die APP deinstallieren zu lassen; das gilt insbesondere
            <br />
            bei technischen Fehlfunktionen der APP;
            <br />
            bei Kompatibilitätsproblemen mit anderen Anwendungen; oder
            <br />
            bei Verstößen des Nutzers gegen diese Nutzungsbedingungen oder die Nutzungsbedingungen des App Store Betreibers.
          </p>
          <p>
            <b>Einräumung der Nutzungsrechte an Inhalten des Nutzers</b>
            <br />
            Der Nutzer erteilt eventbox ein dauerhaftes, nicht-exklusives, kostenfreies, unterlizenzbares, räumlich und inhaltlich unbeschränktes, übertragbares Recht, die vom Nutzer bereitgestellten Inhalte weltweit &#8211; kostenlos oder kostenpflichtig &#8211; zu speichern, zu bearbeiten, zu verbreiten, zu vervielfältigen, abzuspielen sowie öffentlich zugänglich zu machen oder darzubieten.
            <br />
            Der Nutzer erkennt an, dass sowohl die APP als auch der APP-Content von eventbox oder Dritten durch Rechte zum Schutz geistigen Eigentums (gewerbliche Schutzrechte) – insbesondere Urheberrechte – geschützt sind.
            <br />
            Es ist dem Nutzer insbesondere nicht gestattet, ohne vorherige schriftliche Zustimmung von eventbox
            <br />
            die APP bzw. den APP-Content ganz oder teilweise durch den Nutzer selbst oder durch Dritte zu kopieren, zu verändern, kostenpflichtig oder kostenlos zu vertreiben, öffentlich zugänglich zu machen, den Source Code der APP oder den APP-Content zu verändern, zu reproduzieren, zu dekompilieren, zu vertreiben, zu kopieren oder öffentlich zugänglich zu machen, soweit dies nicht von dem bestimmungsgemäßen Gebrauch gemäß Ziffer 2.1 umfasst ist;
            <br />
            die APP oder den APP-Content im Zusammenhang mit rechtswidrigen Inhalten zu verwenden, also insbesondere mit solchen Inhalten, die gewaltverherrlichend, sexistisch, pornographisch, nazistisch, jugendgefährdend oder in sonstiger Weise rechtswidrig sind;
            <br />
            im Internet entgeltlich oder unentgeltlich Leistungen anzubieten, die sich auf die APP oder den APP-Content beziehen und durch die der irrige Eindruck erzeugt wird, hierdurch erhielte der Benutzer Vorteile oder die Leistung würde mit Zustimmung von oder in Zusammenarbeit mit eventbox erbracht.
          </p>
          <p>
            <b>Pflichten des Nutzers</b>
            <br />
            Für die rechtskonforme Verwendung der APP bzw. des Service ist allein der Nutzer verantwortlich. Er versichert, alle gesetzlichen, insbesondere datenschutzrechtlichen und wettbewerbsrechtlichen, sowie vertraglichen Vorgaben bei der Verwendung des Service zu beachten.
            <br />
            Folgende Handlungen sind nicht gestattet:
            <br />
            Nutzung der APP als Minderjähriger;
            <br />
            Verbreitung illegaler Inhalte, insbesondere Inhalte die beleidigend, herabwürdigend, jugendgefährdend, volksverhetzend oder rassistisch sind, oder welche die Betriebs- oder Geschäftsgeheimnisse, geistige Eigentums-, Persönlichkeits- Bild- oder sonstige Rechte Dritter verletzen.
            <br />
            Verbreitung von Schaddateien oder Schadprogrammen (z.B. Viren, Trojaner);
            <br />
            Benutzung automatisierter Verfahren zur Extrahierung von über die APP oder ihre Funktionsweisen ermittelnden Daten;
            <br />
            Nutzung des Zugangs eines anderen Nutzers;
            <br />
            Umgehung der von eventbox eventuell eingesetzten Filtermechanismen sowie der Versuch Bereiche der Dienste bzw. der diese unterstützenden Systemen zu erreichen für deren Zugang der Nutzer keine Berechtigung hat.
            <br />
            Für die regelmäßige Sicherung seiner Daten ist der Nutzer selbst verantwortlich.
            <br />
            Der Nutzer hat eigenverantwortlich zu prüfen, ob er aufgrund oder durch die Nutzung des Service etwaige Gesetze, Verordnungen oder sonstige Regelungen eines Staates oder Landes zu beachten hat, die auf ihn anwendbar sind und die Einhaltung solcher Regelungen sicherzustellen.
            <br />
            Die jeweiligen Vertragsbedingungen und Vorgaben der vom Nutzer verwendeten Messaging-Dienste hat der Nutzer eigenverantwortlich einzuhalten.
            <br />
            Um die bestmögliche Stabilität und Sicherheit der APP zu erhalten, wird dem Nutzer dringend empfohlen, die jeweils aktuellen Updates herunterzuladen und auszuführen.
            <br />
            Vor einem Verkauf des Endgeräts, auf dem sich die APP befindet, muss die APP vollständig entfernt werden.
            <br />
            Der Nutzer bestätigt durch das Herunterladen der APP, dass er
            <br />
            sich nicht in einem Land aufhält, das seitens der US Regierung
            <br />
            mit einem Embargo belegt ist oder
            <br />
            als ein den Terrorismus unterstützendes Land eingestuft wurde; und
            <br />
            nicht auf einer schwarzen Liste („list of prohibited or restricted parties“) der US Regierung steht.
          </p>
          <p>
            <b>Freistellung</b>
            <br />
            Der Nutzer versichert, dass die Art und Weise der Nutzung der APP und des von ihm hochgeladenen APP-Content nicht gegen geltendes Recht oder Ziffer 8.2 verstößt, insbesondere keine Rechte Dritter verletzt.
            <br />
            Sollte es in diesem Zusammenhang dennoch zur Geltendmachung von Ansprüchen Dritter gegenüber eventbox kommen, stellt der Nutzer eventbox von sämtlichen Ansprüchen Dritter frei. Dritter können in diesem Fall auch andere Nutzer oder Organizer sein. Darüber hinaus ersetzt der Nutzer eventbox sämtliche in diesem Zusammenhang entstehenden Schäden und Aufwendungen. Dies gilt insbesondere für den Ersatz der Kosten für eine Verteidigung gegen den geltend gemachten Anspruch (z.B. Gerichts- und Rechtsanwaltskosten). Vorgenannte Freistellungsverpflichtung gilt insbesondere für Verstöße gegen die Ziffern 8.1 (gesetzliche und vertragliche Vorgaben), 8.2 (nicht gestattetes Nutzerverhalten) und 8.4 (zwingende Vorgaben anderer Rechtsordnungen).
          </p>
          <p>
            <b>Gewährleistung / Verfügbarkeit des Service</b>
            <br />
            eventbox stellt dem Nutzer die APP einschließlich des APP-Content mit dem derzeitigen Entwicklungsstand zur Verfügung („as is“). Soweit die APP kostenfrei genutzt wird, übernimmt eventbox insbesondere keine Gewährleistung für etwaige Mängel. Im Falle der kostenpflichtigen In-App-Käufe stehen dem Nutzer hinsichtlich dieser die gesetzlichen Gewährleistungsrechte zu. Soweit der Nutzer die APP durch Updates nicht auf dem neuesten Stand hält (vgl. Ziffer 8.6), kann die Gewährleistung eingeschränkt sein oder entfallen.
            <br />
            eventbox übernimmt keine Gewährleistung für die dauernde Erreichbarkeit des Downloadservers.
            <br />
            Beschreibungen der Funktionsweise der APP sowie Angaben zum Umfang der Funktionalitäten haben lediglich beschreibenden Charakter und stellen keine Garantien dar, sofern sie nicht ausdrücklich und schriftlich als solche bezeichnet werden.
            <br />
            eventbox ist bestrebt, eine höchstmögliche Aktualität, Funktionsfähigkeit und Sicherheit seiner Produkte zu bieten. Um dies zu ermöglichen, können Updates der APP erforderlich sein, die die Verfügbarkeit des APP-Contents vorübergehend ganz oder teilweise beeinträchtigen können. Der Nutzer billigt mögliche hierauf zurückzuführende Beeinträchtigungen in einem verhältnismäßigen und zumutbaren Umfang.
            <br />
            Der Download der APP sowie deren Installation erfolgt auf Kosten und auf Risiko des Nutzers. eventbox ist für Schäden oder sonstige Störungen, die auf der Inkompatibilität der APP bzw. des Service mit der Software oder Hardware des Nutzers beruhen, nicht verantwortlich, es sei denn, die Kompatibilität ist seitens eventbox ausdrücklich so angegeben worden.
            <br />
            eventbox und der Nutzer erkennen an, dass seitens des Betreibers des APP Stores im Rahmen der kostenlosen Nutzung der APP keine Gewährleistung und kein Support für die APP gegeben wird; gleichwohl kann sich der Nutzer im Falle kostenpflichtiger Apps hinsichtlich einer Kostenerstattung an den Betreiber des APP Stores wenden. Eine darüber hinausgehende Inanspruchnahme des Betreibers des APP Stores ist ausgeschlossen.
          </p>
          <p>
            <b>Haftung</b>
            <br />
            eventbox haftet auf Schadenersatz – gleich aus welchem Rechtsgrund – nur bei Vorsatz, grober Fahrlässigkeit und leicht fahrlässiger Verletzung wesentlicher Vertragspflichten. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist. Bei leicht fahrlässiger Verletzung wesentlicher Vertragspflichten ist die Haftung von eventbox begrenzt auf den Ersatz des vertragstypischen, vorhersehbaren Schadens. Insbesondere mittelbare Schäden werden nicht ersetzt. Die vorstehenden Haftungsbeschränkungen gelten nicht für schuldhaft verursachte Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit sowie in sonstigen Fällen zwingender gesetzlicher Haftung.
            <br />
            Soweit im Rahmen der APP Werbung Dritter bereitgestellt wird, wird für deren Inhalt keine Haftung übernommen. Auf die Inhalte dieser Werbung sowie der verlinkten Websites hat eventbox keinen Einfluss und macht sie sich ausdrücklich nicht zu Eigen.
            <br />
            eventbox haftet nicht für die Inhalte, die von Dritten, insbesondere Organizern, auf der App bereitgestellt werden.
            <br />
            eventbox haftet nicht für Absagen von über die App beworbenen Veranstaltungen. Für das Stattfinden und den Zugang zu den einzelnen Veranstaltungen ist nur der entsprechende Organizer verantwortlich. Eine Rückerstattung des Ticketpreises durch eventbox aufgrund Zutrittsverweigerung zur Veranstaltung durch den Veranstalter, die eventbox nicht zu vertreten hat, kann nicht verlangt werden.
          </p>
          <p>
            <b>Hinweise zur Datenverarbeitung</b>
            <br />
            Sofern eventbox oder beauftragte Partner (nicht der App-Store) im Rahmen der Installation und/oder Nutzung der APP personenbezogene Daten des Nutzers erheben und speichern, erfolgt dies nach Maßgabe des geltenden Datenschutzrechts, insbesondere gemäß der DSGVO und dem Bundesdatenschutzgesetz. Für weitere Informationen zur Datenerhebung, -verarbeitung und -nutzung wird auf die Datenschutzerklärung.
          </p>
          <p>
            <b>Anpassung der Nutzungsbedingungen</b>
            <br />
            eventbox behält sich das Recht vor, diese Nutzungsbedingungen ohne Angabe von Gründen von Zeit zu Zeit anzupassen, es sei denn dies ist für den Nutzer unzumutbar. Dies ist etwa der Fall, wenn die Änderung die kostenpflichtige Version der APP betrifft und sich die Änderung zum Nachteil des Nutzers auswirken würde.
            <br />
            eventbox wird dem Nutzer die Änderungen rechtzeitig mitteilen. Diese Änderungen gelten als genehmigt, wenn der Nutzer diesen Änderungen nicht binnen 6 Wochen ab Zugang der Änderungsmitteilung schriftlich (z.B. E-Mail) widerspricht, wobei eventbox in der Änderungsmitteilung ausdrücklich auf das Widerspruchsrecht und die Folge eines unterbliebenen Widerspruchs hinweisen wird.
            <br />
            Unbesehen der Ziffern 13.1 und 13.2 kann eventbox diese Nutzungsbedingungen ändern,
            <br />
            wenn die Änderung lediglich vorteilhaft für den Nutzer ist;
            <br />
            wenn die Änderung rein technisch oder durch einen Systemprozess bedingt ist, es sei denn, sie hat wesentliche Auswirkungen für den Nutzer;
            <br />
            soweit eventbox aufgrund zwingender rechtlicher Vorgaben zu einer Änderung verpflichtet ist, wie bspw. bei der Änderung der geltenden Rechtslage;
            <br />
            soweit eventbox zusätzliche Leistungen einführt, es sei denn, der bestehende Nutzungsvertrag wird dadurch für den Nutzer nachteilig verändert.
          </p>
          <p>
            <b>Dauer der Nutzung des Service / Kündigung</b>
            <br />
            Der Nutzungsvertrag zwischen eventbox und dem Nutzer zur Nutzung der APP hat eine unbefristete Laufzeit.
            <br />
            Der Nutzungsvertrag kann vom Nutzer ohne Angabe von Gründen und ohne Einhaltung einer Frist jederzeit gekündigt werden. Hierfür muss eine E-Mail, aus der der Kündigungswille eindeutig hervor geht, an info@eventboxde.com geschickt werden.
            <br />
            eventbox kann den Vertrag mit dem Nutzer zur Nutzung der APP jederzeit ohne Angaben von Gründen kündigen, es sei denn, der Nutzer hat eine kostenpflichtige Leistung gebucht und diese ist noch nicht vollständig erbracht. In diesem Falle ist eventbox berechtigt, nach seiner Wahl (1) den Vertrag nach Erbringung der kostenpflichtigen Leistungen unmittelbar zu beenden oder (2) vom Vertrag über die kostenpflichtige Leistung zurückzutreten, dem Nutzer sein Entgelt vollständig zu erstatten und den Vertrag unmittelbar zu beenden.
            <br />
            Beide Parteien haben das Recht, den kostenpflichtigen Nutzungsvertrag aus wichtigem Grund außerordentlich zu kündigen. Ein wichtiger Grund zur außerordentlichen Kündigung liegt für eventbox insbesondere dann vor, wenn
            <br />
            der Nutzer gegen wesentliche Pflichten aus diesem Vertrag verstößt (insbesondere gegen Ziffer 8.2) und diese Verstöße trotz vorheriger Abmahnung und Fristsetzung nicht abstellt;
            <br />
            der Nutzer Inhalte zu gewerblichen Zwecken verwertet oder zu sonstigen nicht privaten Zwecken verbreitet, sendet, öffentlich zugänglich macht oder öffentlich vorführt.
          </p>
          <p>
            <b>Widerruf</b>
            <br />
            Bei der Buchung von Tickets für Veranstaltungen besteht gemäß § 312g Abs. 2 Nr. 9 BGB kein Widerrufsrecht. Jede Bestellung von Tickets ist damit unmittelbar nach Bestätigung bindend und verpflichtet zur Abnahme und Bezahlung der bestellten Tickets.
          </p>
          <p>
            <b>Folgen des Widerrufs</b>
            <br />
            Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
          </p>
          <p>
            Ausnahme zum Widerrufsrecht: Sie können eine Bestellung für digitale Inhalte nicht widerrufen, wenn die Lieferung mit Ihrer ausdrücklichen Zustimmung und Ihrer Kenntnisnahme, dass Sie dadurch Ihr Widerrufsrecht verlieren, begonnen hat.
          </p>
          <p>&#8211; Ende der Widerrufsbelehrung &#8211;</p>
          <p>
            <strong>Schlussbestimmungen</strong>
            <br />
            Mit der Registrierung erklärt der Nutzer, diese Nutzungsbedingungen sowie die gelesen zu haben und mit ihrer Geltung einverstanden zu sein.
            <br />
            Nebenabreden, Änderungen und Ergänzungen des Vertragsverhältnisses bedürfen der Schriftform. Das gilt auch für einen Verzicht auf dieses Schriftformerfordernis.
            <br />
            Das Vertragsverhältnis mit dem Nutzer sowie sämtliche Ansprüche aus oder im Zusammenhang mit dem Vertrag (z.B. auch außervertragliche Ansprüche) unterliegen dem Recht der Bundesrepublik Deutschland. Das UN-Kaufrecht findet keine Anwendung.
            <br />
            Erfüllungsort ist der Sitz von eventbox.
            <br />
            Sofern es sich bei dem Nutzer um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis zwischen dem Nutzer und eventbox der Sitz von eventbox.
            <br />
            Bei einem etwaigen Widerspruch zwischen verschiedenen Sprachversionen dieser Nutzungsbedingungen bzw. bei Auslegungsfragen ist die deutsche Fassung maßgebend.
            <br />
            Diese Nutzungsbedingungen gelten ausschließlich. Abweichende, entgegenstehende oder ergänzende Nutzungsbedingungen werden nur dann und insoweit Vertragsbestandteil, als eventbox ihrer Geltung ausdrücklich schriftlich zugestimmt hat.
          </p>
          <p>
            Datenschutzerklärung
            <br />
            &#8211; eventbox &#8211;
          </p>
          <p>
            <strong>Anbieter von eventbox</strong>
            <br />
            Die eventbox, E-Mail: info@eventboxde.com (nachfolgend „Anbieter“), betreibt das unter eventboxde.com aufrufbare Angebot sowie die auf gängigen Plattformen verfügbare App „eventbox“ mit den dazugehörigen Unterseiten (nachfolgend „Portal“).
            <br />
            Der Anbieter nimmt den Schutz Ihrer persönlichen Daten sehr ernst und beachtet die anwendbaren Datenschutzvorschriften, insbesondere die Datenschutzgrundverordnung (DSGVO) und das Bundesdatenschutzgesetz (BDSG). Die nachfolgende Erklärung gibt Ihnen einen Überblick darüber, wie der Anbieter diesen Schutz gewährleistet und welche Art von Daten zu welchem Zweck erhoben werden.
            <br />
            Bei Fragen zu oder Problemen mit datenschutzrechtlichen Belangen wenden Sie sich bitte an unseren Datenschutzbeauftragten: <a href="mailto:info@eventboxde.com">info@eventboxde.com</a>
          </p>
          <p>
            <strong>Erhebung, Verarbeitung und Nutzung allgemeiner Daten</strong>
            <br />
            Der Anbieter erhebt und speichert bei dem Aufruf oder der Nutzung des Portals bzw. dessen Unterseiten automatisch Informationen, die Ihr Browser oder Endgerät – sofern die entsprechenden Funktionen nicht deaktiviert sind – an den Anbieter übermittelt. Diese Daten werden ausschließlich zur Sicherstellung eines störungsfreien Betriebs des Portals und zur Verbesserung des Angebots ausgewertet und erlauben dem Anbieter keinen Rückschluss auf Ihre Person. Dies sind:
            <br />
            Hostname des zugreifenden Endgerätes
            <br />
            IP Adresse des Endgerätes
            <br />
            Datum und Uhrzeit der Serveranfrage
            <br />
            Bezeichnung der abgerufenen Dateien bzw. Informationen
            <br />
            Übertragene Datenmenge
            <br />
            Betriebssystem und Informationen zum auf das Portal zugreifenden Endgerätes.
          </p>
          <p>
            <strong>Personenbezogene Daten</strong>
            <br />
            Personenbezogene Daten werden im Rahmen der Nutzung des Portals nur wie im Folgenden beschrieben erhoben, verarbeitet und genutzt. Personenbezogene Daten sind sämtliche Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person (nicht umfasst: juristische Personen, wie etwa eine GmbH oder AG). Zu den personenbezogenen Daten gehören vor allem Angaben wie der Name, die Anschrift, die E-Mail-Adresse oder berufliche Verhältnisse einer Person.
          </p>
          <p>
            <strong>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</strong>
            <br />
            Der Anbieter erhebt, verarbeitet und speichert Ihre personenbezogenen Daten, wenn Sie dem Anbieter diese Ihrerseits im Rahmen der Nutzung des Portals, der Bestellung oder Vermittlung von Waren oder Leistungen des Anbieters oder Dritter, einer anderweitigen Nutzung des Portals oder einer sonstigen Kontaktaufnahme (z.B. per E-Mail) mitteilen.
            <br />
            Im Rahmen der oben erwähnten Interaktion mit Ihnen werden nur die hierfür jeweils erforderlichen Daten erhoben. Darüber hinaus können weitere Daten abgefragt werden, die für die Beratung und die bedarfsgerechte Information hilfreich sind; die Eingabe dieser Daten ist jedoch freiwillig und entsprechend gekennzeichnet. Der Anbieter verwendet die von Ihnen mitgeteilten Daten zur Bearbeitung Ihrer Anfragen oder der Vertragsabwicklung. Damit Sie dem Anbieter im Falle von Anfragen bzw. bei laufenden Beratungen nicht wiederholt die gleichen Informationen mitteilen müssen, speichert er diese solange ab, wie sie für die Klärung der Anfrage oder die Kundenbeziehung benötigt werden.
            <br />
            Die Datenverarbeitung erfolgt auf Basis von Art. 6. Abs. 1 Satz 1 lit. b DSGVO, wenn sie einer Vertragsabwicklung oder vertragsvorbereitenden Maßnahmen dient. Soweit Sie in die Datenerhebung und Datenverarbeitung eingewilligt haben, richtet sich die Datenverarbeitung nach § Art. 6 Abs. 1 Satz 1 lit. a DSGVO.
            <br />
            Alle personenbezogenen Daten werden auf Servern in Deutschland oder innerhalb der EU gespeichert oder verarbeitet. Eine Datenübermittlung an Stellen außerhalb der EU erfolgt nur im nach Art. 44 DSGVO zulässigen Rahmen.
          </p>
          <p>
            Ihnen steht ein Widerspruchsrecht gegen die Erhebung der Daten zu Direktmarketingzwecken zu. Um dieses Widerrufsrecht auszuüben, können Sie uns unter Nennung Ihrer E-Mail-Adresse eine formlose Nachricht an info@eventboxde.com senden, in der Ihre Widerrufsabsicht zum Ausdruck kommt.
          </p>
          <p>
            <strong>Datenübermittlung an Dritte</strong>
            <br />
            Der Anbieter wird Ihre personenbezogenen Daten nur in den folgenden Fällen an Dritte weitergeben:
            <br />
            Wenn ein Dienstleister, für dessen Leistungen Sie sich interessieren, diese Daten zur Kontaktaufnahme mit Ihnen benötigt. Diese Dienstleister sind, soweit Ihnen nicht ausdrücklich etwas anderes mitgeteilt wird und Sie eine Einwilligung erteilen, nur insoweit zur Verwendung Ihrer Daten berechtigt, als dies für den jeweiligen Zweck erforderlich oder von Ihnen genehmigt ist; oder
            <br />
            Wenn die Übermittlung sonst zur Erbringung einer von Ihnen angefragten bzw. beauftragten Leistung (z.B. Zuordnung der E-Tickets) notwendig ist; oder
            <br />
            Wenn eine zwingende behördliche oder gerichtliche Anordnung vorliegt.
          </p>
          <p>
            <strong>Löschung und Berichtigung von Daten</strong>
            <br />
            Auf Verlangen bzw. nach Kündigung des Vertrages mit dem Anbieter werden Ihre personenbezogenen Daten umgehend gelöscht, wenn dem nicht steuer- oder handelsrechtliche Aufbewahrungs- oder Dokumentationspflichten entgegenstehen oder die Sicherstellung der berechtigten Interessen des Anbieters gefährdet ist.
            <br />
            Solange noch nicht alle vertraglichen Pflichten erfüllt sind und Ihre Daten hierzu erforderlich sind, besteht kein Anspruch auf Datenlöschung.
            <br />
            Sollten Daten fehlerhaft sein, so werden diese bei Kenntnis oder auf Ihr Verlangen hin berichtigt.
          </p>
          <p>
            <strong>Auskunftsrecht / Weitere Rechte</strong>
            <br />
            Auf Verlangen erteilt der Anbieter Ihnen insbesondere Auskunft über
            <br />
            die erfolgte Erhebung personenbezogener Daten,
            <br />
            den Zweck der Datenverarbeitung,
            <br />
            die Kategorien der verarbeiteten Daten, sowie
            <br />
            ggf. die Empfänger, denen aufgrund gesetzlicher Verpflichtungen oder vertraglicher Beziehungen Daten offengelegt werden.
            <br />
            Auf Verlangen erhalten Sie seitens des Anbieters eine Kopie der von Ihnen erhobenen und verarbeiteten Daten.
            <br />
            Auf Verlangen können Ihre Daten kostenpflichtig in einem für Sie und einen im Anschluss tätigen Dienstleister lesbaren Format zur Verfügung gestellt werden, um eine schnelle Übertragung zu ermöglichen.
            <br />
            Bei Verstößen gegen Datenschutzvorschriften steht Ihnen ein Beschwerderecht zu, das sie bei der zuständigen Aufsichtsbehörde, dem Bayerischen Landesamt für Datenschutzaufsicht ausüben können.
          </p>
          <p>
            <strong>Cookies</strong>
            <br />
            Das Portal verwendet an mehreren Stellen so genannte „Cookies“. Sie dienen u.a. dazu, das Angebot des Portals nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind Text-Informationen, die eine Webseite an die Cookie-Datei des Browsers auf Ihrer Festplatte oder sonstigem Endgerät übermittelt, so dass die Website sich u.a. erinnern kann, welches Endgerät auf sie zugegriffen hat. Ein Cookie beinhaltet typischerweise den Namen der Domain, von dem der Cookie stammt, die „Lebenszeit“ des Cookies, und einen Wert, in der Regel eine zufällig generierte einmalige Nummer. Das Portal setzt folgende Arten von Cookies ein:
            <br />
            Session Cookies: Dies sind vorübergehend gespeicherte Cookies, die in der Cookie-Datei Ihres Browsers verbleiben, bis Sie die Website verlassen. Session Cookies ermöglichen es, das Endgerät während der Dauer der Nutzung identifizieren zu können. Diese Informationen werden nicht weiter gespeichert, nachdem Sie die Website verlassen haben.
            <br />
            Persistent Cookies: Diese verbleiben für längere Zeit in der Cookie-Datei Ihres Browsers. Wie lange, hängt von der „Lebenszeit“ des speziellen Cookies ab. Nach Ablauf dieser Lebenszeit löscht sich das Cookie selbst. Der Zweck des Einsatzes dieser dauerhaften Cookies besteht darin, die Funktionalität des Portals (bspw. Warenkorb im Webshop) zu gewährleisten. Die permanenten Cookies enthalten keine personenbezogenen Daten. Name, IP-Adresse etc. werden darin nicht gespeichert.
            <br />
            Web Beacons: Dies sind elektronische Zeichen (auch „Clear GIFs“ oder „Web Bugs“ genannt), die es dem Anbieter gestatten, die Anzahl der Nutzer zu zählen, die das Portal besucht haben.
            <br />
            Falls Sie Cookies oder Web Beacons nicht akzeptieren möchten, können Sie diese ablehnen und dem Zugriff auf zuvor gespeicherte Informationen widersprechen, indem Sie Ihren Web-Browser entsprechend einstellen. Die Einstellungen innerhalb Ihres Web-Browsers, die Ihnen gestatten, dies zu tun, unterscheiden sich von Browser zu Browser, sie können in der Regel unter „Datenschutz“ oder „Cookies“ des „Internetoptionen“- oder „Eigenschaften“-Menüs Ihres Browsers gefunden werden. Falls Sie Hilfe benötigen, um Cookies abzustellen, sollten Sie auf das „Hilfe“-Menü innerhalb Ihres Browsers zurückgreifen. Bitte beachten Sie jedoch, dass Sie dann eventuell nicht in der Lage sein werden, alle Funktionen des Portals zu nutzen, falls Cookies und/oder Web Beacons ausgeschaltet sind.
          </p>
          <p>
            <strong>Google Analytics</strong>
            <br />
            Der Anbieter benutzt Google Analytics, einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics verwendet ebenfalls Cookies (vgl. die Beschreibung unter Ziffer 7), die eine Analyse der Benutzung des Portals durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung des Portals werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Auf diesem Portal wurde die IP-Anonymisierung aktiviert, so dass die IP-Adresse der Nutzer von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt wird. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Anbieters wird Google diese Informationen benutzen, um Ihre Nutzung des Portals auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Anbieter zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
            <br />
            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; der Anbieter weist Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung des Portals bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:
            <br />
            https://tools.google.com/dlpage/gaoptout?hl=de .
          </p>
          <p>
            <strong>Social Plugins</strong>
            <br />
            Der Anbieter verwendet die Social Plugins, die nachfolgend aufgeführt werden.
          </p>
          <p>
            <strong>Facebook</strong>
            <br />
            Das Portal verwendet Social Plugins des sozialen Netzwerks facebook.com, welches von der Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA, betrieben wird (“Facebook”). Die Plugins werden zum Beispiel für den Login mittels des Facebook-Accounts genutzt und sind mit einem Facebook Logo gekennzeichnet. Wenn Sie das Portal aufrufen, wird eine direkte Verbindung mit den Servern von Facebook aufgebaut. Der Inhalt des Plugins wird von Facebook direkt übermittelt und von diesem in das Portal eingebunden. Durch die Einbindung der Plugins erhält Facebook u.a. die Information, dass Sie die entsprechende Seite des Anbieters aufgerufen haben. Sind Sie bei Facebook eingeloggt, kann Facebook Ihren Besuch Ihrem Facebook-Konto zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den “Gefällt mir” Button betätigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Browser direkt an Facebook übermittelt und dort gespeichert. Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie diesbezügliche Rechte und Einstellungsmöglichkeiten Ihrerseits zum Schutz Ihrer Privatsphäre ist den Datenschutzhinweisen von Facebook zu entnehmen:
            <br />
            https://www.facebook.com/about/privacy.Sollten Sie nicht wollen, dass Facebook Ihre Daten verwendet, müssen Sie sich vor Ihrem Besuch des Portals bei Facebook ausloggen. Falls Sie kein Mitglied von Facebook sind oder sich vor dem Besuch des Portals ausgeloggt haben, besteht dennoch die Möglichkeit, dass zumindest Ihre IP-Adresse übermittelt und dort gespeichert wird.
          </p>
          <p>
            <strong>Paypal Zahlunsgtool</strong>
            <br />
            Für Zahlungen nutzt der Anbieter ein Tool von Paypal. Hierbei werden Daten mit Paypal, einem Dienst der PayPal (Europe) S.a.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg („Paypal“), ausgetauscht. Zu den durch Paypal verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die Adresse, ggf. hinterlegte Bankdaten wie Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen und transaktionsbedingten Angaben. Die Angaben sind erforderlich, um die Zahlung auszuführen. Die eingegebenen Daten werden jedoch nur durch Paypal verarbeitet und gespeichert. Der Anbieter erhält keine konto- oder kreditkartenbezogenen Informationen, sondern lediglich Informationen zur Bestätigung oder Nichtausführbarkeit der Zahlung. Unter Umständen werden die Daten seitens Paypal an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt eine Identitäts- und Bonitätsprüfung. Hierzu verweist der Anbieter auf die AGB und Datenschutzhinweise von Paypal, die Sie hier abrufen können: <a href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full#PayPal">https://www.paypal.com/de/webapps/mpp/ua/privacy-full#PayPal</a>.
          </p>
          <p>
            <strong>Doubleclick</strong>
            <br />
            Doubleclick by Google (“Doubleclick“) ist eine Dienstleistung von Google. Doubleclick verwendet Cookies, um Ihnen für Sie relevante Werbeanzeigen zu präsentieren. Dabei wird Ihrem Browser eine pseudonyme Identifikationsnummer (ID) zugeordnet, um zu überprüfen, welche Anzeigen in Ihrem Browser eingeblendet wurden und welche Anzeigen aufgerufen wurden. Die Cookies enthalten keine personenbezogenen Informationen. Die Verwendung der DoubleClick-Cookies ermöglicht Google und seinen Partner-Webseiten lediglich die Schaltung von Anzeigen auf Basis vorheriger Besuche auf unserer oder anderen Webseiten im Internet. Die durch die Cookies erzeugten Informationen werden von Google zur Auswertung an einen Server in den USA übertragen und dort gespeichert. Eine Übertragung der Daten durch Google an Dritte findet nur aufgrund gesetzlicher Vorschriften oder im Rahmen der Auftragsdatenverarbeitung statt. Keinesfalls wird Google ihre Daten mit anderen von Google erfassten Daten zusammenbringen.
            <br />
            Durch die Nutzung der Webseiten des Anbieters erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google und der zuvor beschriebenen Art und Weise der Datenverarbeitung sowie dem benannten Zweck einverstanden. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; es wird jedoch darauf hingewiesen, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen der Webseiten des Anbieters vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch die Cookies erzeugten und auf Ihre Nutzung der Webseiten bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link unter dem Punkt DoubleClick-Deaktivierungserweiterung verfügbare Browser-Plugin herunterladen und installieren. Alternativ können Sie die Doubleclick-Cookies auf der Seite der Network Advertising Initiative (NAI) unter dem folgenden Link deaktivieren.
          </p>
          <p>
            <strong>Retargeting</strong>
            <br />
            Der Anbieter verwendet sogenannte Re-Targeting-Technologien. Diese Technik ermöglicht es, Internetnutzer, die sich bereits für den Shop und die Produkte des Anbieters interessiert haben, auf den Webseiten anderer Partner mit Werbung anzusprechen. Die Einblendung dieser Werbemittel erfolgt auf Basis einer Cookie-Technologie und einer Analyse des vorherigen Nutzungsverhaltens. Diese Form der Werbung erfolgt vollkommen pseudonym. Es werden keine personenbezogenen Daten gespeichert und es werden auch keine Nutzungsprofile mit Ihren personenbezogenen Daten zusammengeführt.
            <br />
            In Ihrem Browser können Sie einstellen, dass eine Speicherung von Cookies nur akzeptiert wird, wenn Sie dem zustimmen. Wenn Sie nur die Cookies des Portals, nicht aber die Cookies der Dienstleister und Partner akzeptieren wollen, können Sie die Einstellung in Ihrem Browser „Cookies von Drittanbietern blockieren“ wählen.
            <br />
            In der Regel wird Ihnen in der Menüleiste Ihres Webbrowsers über die Hilfe-Funktion angezeigt, wie Sie neue Cookies abweisen und bereits erhaltene ausschalten können. Alternativ können Sie die Verwendung von Cookies durch Drittanbieter deaktivieren, indem Sie die Deaktivierungsseite der EDAA (European Interactive Digital Advertising Alliance) aufrufen.
          </p>
          <p>
            <strong>Änderung dieser Datenschutzerklärung</strong>
            <br />
            Der Anbieter behält sich das Recht vor, Sicherheits- und Datenschutzmaßnahmen anzupassen, soweit dies wegen der technischen oder rechtlichen Entwicklung erforderlich wird. In diesen Fällen wird der Anbieter auch diese Erklärung zum Datenschutz entsprechend anpassen. Bitte beachten Sie daher die jeweils aktuelle Version unserer Datenschutzerklärung.
          </p>
          <p>
            <strong>Fragen und Kontakt</strong>
            <br />
            Sie haben ein Recht auf unentgeltliche Auskunft über die bei dem Anbieter zu Ihrer Person gespeicherten Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von Daten sowie Widerruf ggf. erteilter Einwilligungen oder Widerspruch gegen eine bestimmte Datenverwendung wenden Sie sich bitte an <a href="mailto:info@eventboxde.com">info@eventboxde.com</a>.
          </p>
          <p>
            Wir können jederzeit und in unserem alleinigen Ermessen unvollständige oder unrichtige Daten korrigieren, vervollständigen oder entfernen.
          </p>
          <p>
            <strong>Grundlage für die Datenerfassung</strong>
          </p>
          <p>
            Die Verarbeitung Ihrer personenbezogenen Daten (d. h. jegliche Daten, die mit vertretbaren Mitteln eine Identifizierung Ihrer Person zulassen; „<strong>personenbezogene Daten</strong>“) ist erforderlich, um unseren vertraglichen Verpflichtungen Ihnen gegenüber nachzukommen und damit wir Ihnen unsere Services bereitstellen, unser legitimes Interesse schützen sowie rechtlichen und finanziellen Regulierungsverpflichtungen, denen wir unterworfen sind, nachkommen können.
          </p>
          <p>
            Durch die Nutzung dieser Webseite stimmen Sie der Erfassung, Speicherung, Verwendung, Offenlegung und sonstigen Nutzung Ihrer personenbezogenen Daten wie in diesen Datenschutzbestimmungen beschrieben zu.
          </p>
          <p>
            Bitte lesen Sie sich die Datenschutzbestimmungen sorgfältig durch, bevor Sie Entscheidungen treffen.
          </p>
          <p>
            <strong>Welche Daten werden erfasst?</strong>
          </p>
          <p>
            Wir erfassen zwei Arten von Daten und Informationen von Benutzern.
          </p>
          <p>
            Zur ersten Kategorie gehören nicht identifizierende und nicht identifizierbare Benutzerdaten, die durch die Nutzung der Webseite bereitgestellt oder erfasst werden („<strong>Nicht personenbezogene Daten</strong>”). Wir kennen die Identität des Benutzers nicht, von dem nicht personenbezogene Daten erfasst wurden. Zu den nicht personenbezogenen Daten, die erfasst werden können, gehören aggregierte Nutzungsdaten und technische Daten, die von Ihrem Gerät übermittelt werden, einschließlich bestimmter Informationen bezüglich Software und Hardware (z. B. auf dem Gerät verwendeter Browser und verwendetes Betriebssystem, Spracheinstellung, Zugriffszeit usw.). Anhand dieser Daten verbessern wir die Funktionalität unserer Webseite. Wir können auch Daten über Ihre Aktivität auf der Webseite erfassen (z. B. aufgerufene Seiten, Surfverhalten, Klicks, Aktionen usw.).
          </p>
          <p>
            Zur zweiten Kategorie gehören <strong>personenbezogene Daten</strong> , also Daten, die eine Einzelperson identifizieren oder durch angemessene Maßnahmen identifizieren können. Zu solchen Daten gehören:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              Gerätedaten: Wir erfassen personenbezogene Daten von Ihrem Gerät. Solche Daten umfassen Geolocation-Daten, IP-Adresse.
            </li>
          </ul>
          <h2 :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">
            Wie erhalten wir Daten über Sie?
          </h2>
          <p>
            Wir beziehen Ihre personenbezogenen Daten aus verschiedenen Quellen:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              Sie stellen uns solche Daten freiwillig bereit, zum Beispiel bei der Registrierung auf unserer Webseite.
            </li>
            <li>
              Wir erhalten solche Daten, wenn Sie unsere Webseite nutzen oder in Verbindung mit einem unserer Services darauf zugreifen.
            </li>
            <li>
              Wir erhalten solche Daten von anderen Anbietern, Services und aus öffentlichen Registern (zum Beispiel von Datentraffic-Analyseanbietern).
            </li>
          </ul>
          <h2 :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">
            Wie werden die Daten genutzt? An wen werden die Daten weitergegeben?
          </h2>
          <p>
            Wir geben Benutzerdaten nicht an Dritte weiter, außer wie in diesen Datenschutzbestimmungen beschrieben.
          </p>
          <p>
            Wir verwenden Daten für folgende Zwecke: (Intern)
            <br />
            Neben den verschiedenen, oben aufgeführten Verwendungszwecken können wir personenbezogene Daten auch an unsere Tochtergesellschaften, verbundenen Unternehmen und Subunternehmer weitergeben.
          </p>
          <p>
            Zusätzlich zu den in diesen Datenschutzbestimmungen aufgeführten Zwecken können wir personenbezogene Daten aus folgenden Gründen an unsere vertrauenswürdigen externen Anbieter weiterleiten, die ihren Sitz in unterschiedlichen Rechtsprechungen auf der ganzen Welt haben:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              Hosten und Betreiben unserer Webseite
            </li>
          </ul>
          <p>
            Wir können Daten auch offenlegen, wenn wir im guten Glauben sind, dies ist hilfreich oder angemessen, um: (i) geltenden Gesetzen, Vorschriften, Gerichtsverfahren oder behördlichen Anfragen zu entsprechen; (ii) unsere Richtlinien (einschließlich unserer Vereinbarung) durchzusetzen und ggf. diesbezügliche mögliche Verletzungen zu untersuchen; (iii) illegale Aktivitäten oder anderes Fehlverhalten, Betrugsverdacht oder Sicherheitsprobleme zu untersuchen, zu erkennen, zu verhindern oder Maßnahmen dagegen zu ergreifen; (iv) eigene Rechtsansprüche geltend zu machen oder durchzusetzen bzw. uns gegen die Ansprüche anderer zu verteidigen; (v) die Rechte, das Eigentum oder unsere Sicherheit, die Sicherheit unserer Benutzer, Ihre Sicherheit oder die Sicherheit von Dritten zu schützen; oder um (vi) mit Strafverfolgungsbehörden zusammenzuarbeiten und/oder geistiges Eigentum oder andere Rechtsansprüche zu schützen.
          </p>
          <h2 :class="`font-bold color-purple ${isMobile ? 'font-18' : 'font-30'}`">
            Cookies
          </h2>
          <p>
            Für die Bereitstellung entsprechender Services verwenden wir und unsere Partner Cookies. Dies gilt auch, wenn Sie unsere Webseite besuchen oder auf unsere Services zugreifen.
          </p>
          <p>
            Bei einem „Cookie“ handelt es sich um ein kleines Datenpaket, das Ihrem Gerät beim Besuch einer Webseite von dieser Webseite zugeordnet wird. Cookies sind nützlich und können für unterschiedliche Zwecke eingesetzt werden. Dazu gehören z. B. die erleichterte Navigation zwischen verschiedenen Seiten, die automatische Aktivierung bestimmter Funktionen, das Speichern Ihrer Einstellungen sowie ein optimierter Zugriff auf unsere Services. Die Verwendung von Cookies ermöglicht uns außerdem, Ihnen relevante, auf Ihre Interessen abgestimmte Werbung einzublenden und statistische Informationen zu Ihrer Nutzung unserer Services zu sammeln.
          </p>
          <p>
            Diese Webseite verwendet folgende Arten von Cookies:
          </p>
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              <strong>a. „Sitzungscookies“</strong> , die für eine normale Systemnutzung sorgen. Sitzungscookies werden nur für begrenzte Zeit während einer Sitzung gespeichert und von Ihrem Gerät gelöscht, sobald Sie Ihren Browser schließen.
            </li>
            <li>
              <strong>b. „Permanente Cookies“</strong>, die nur von der Webseite gelesen und beim Schließen des Browserfensters nicht gelöscht, sondern für eine bestimmte Dauer auf Ihrem Computer gespeichert werden. Diese Art von Cookie ermöglicht uns, Sie bei Ihrem nächsten Besuch zu identifizieren und beispielsweise Ihre Einstellungen zu speichern.
            </li>
            <li>
              <strong>c. „Drittanbieter-Cookies“</strong> , die von anderen Online-Diensten gesetzt werden, die mit eigenen Inhalten auf der von Ihnen besuchten Seite vertreten sind. Dies können z. B. externe Web-Analytics-Unternehmen sein, die den Zugriff auf unsere Webseite erfassen und analysieren.
            </li>
          </ul>
          <p>
            Cookies enthalten keine personenbezogenen Daten, die Sie identifizieren, doch die von uns gespeicherten personenbezogenen Daten werden möglicherweise von uns mit den in den Cookies enthaltenen Daten verknüpft. Sie können Cookies über die Geräteeinstellungen Ihres Gerät entfernen. Folgen Sie dabei den entsprechenden Anweisungen. Beachten Sie, dass die Deaktivierung von Cookies zur Einschränkung bestimmter Funktionen bei der Nutzung unserer Webseite führen kann.
          </p>
          <p>
            <strong>Erfassung von Daten durch Dritte</strong>
            <br />
            In dieser Richtlinie werden nur die Nutzung und Offenlegung von Daten, die wir von Ihnen erfassen, behandelt. Wenn Sie Daten auf anderen Webseiten veröffentlichen oder Dritten im Internet offenlegen, gelten möglicherweise andere Bestimmungen. Lesen Sie sich daher die allgemeinen Geschäftsbedingungen und Datenschutzbestimmungen immer sorgfältig durch, wenn Sie Daten offenlegen.
          </p>
          <p>
            Diese Datenschutzbestimmungen beziehen sich nicht auf Geschäftspraktiken von Unternehmen, die sich nicht in unserem Besitz befinden bzw. nicht unter unserer Kontrolle stehen, oder auf andere Personen als unsere Angestellten und Mitarbeiter, einschließlich Dritter, denen wir diese Daten wie in diesen Datenschutzbestimmungen beschrieben offenlegen.
          </p>
          <p>
            <strong>Wie schützen wir Ihre Daten?</strong>
            <br />
            Wir setzen die Sicherheitsmaßnahmen auf der Webseite mit großer Sorgfalt um und schützen Ihre Daten. Obwohl wir angemessene Schritte für den Schutz von Daten unternehmen, können wir nicht verantwortlich gemacht werden für Handlungen von jenen, die sich unbefugten Zugang zu unserer Webseite verschafft haben oder diese missbräuchlich verwenden, und wir geben keine ausdrückliche noch stillschweigende Gewähr, dass wir einen solchen Zugriff verhindern können.
          </p>
          <p>
            Übermittlung von Daten außerhalb des Europäischen Wirtschaftsraums
            <br />
            Beachten Sie, dass einige Empfänger ihren Sitz möglicherweise nicht im Europäischen Wirtschaftsraum haben. Ist dies der Fall, werden wir Ihre Daten nur in von der Europäischen Kommission genehmigte Länder mit angemessenem Datenschutzniveau übermitteln oder durch eine rechtliche Vereinbarung ein angemessenes Datenschutzniveau sicherstellen.
          </p>
          <p>
            <strong>Marketing</strong>
            <br />
            Wir können Ihre personenbezogenen Daten wie Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer usw. selbst verwenden oder an einen externen Untervertragsnehmer weiterleiten, um Ihnen Werbematerialien bezüglich unserer Services bereitzustellen, die Sie möglicherweise interessieren.
          </p>
          <p>
            Wir respektieren Ihr Recht auf Privatsphäre. Daher erhalten Sie in diesen Marketingmaterialien stets die Möglichkeit, sich von weiteren Zusendungen abzumelden. Wenn Sie sich abmelden, wird Ihre E-Mail-Adresse oder Telefonnummer aus unseren Marketing-Verteilerlisten entfernt.
          </p>
          <p>
            Beachten Sie, dass wir Ihnen auch nach einer Abmeldung vom Erhalt unserer Marketing-E-Mails weiterhin E-Mails mit wichtigen Informationen senden, die keine Abmeldeoption enthalten. Dazu gehören Wartungsmeldungen oder administrative Benachrichtigungen.
          </p>
          <p>
            <strong>Unternehmenstransaktion</strong>
            <br />
            Wir können Daten im Fall einer Unternehmenstransaktion (z. B. beim Verkauf wesentlicher Unternehmensteile, bei einer Fusion, einer Konsolidierung oder einem Anlagenverkauf) weitergeben. Falls ein oben genannter Fall eintritt, übernimmt der Erwerber oder das entsprechende Unternehmen die in dieser Datenschutzerklärung dargelegten Rechte und Pflichten.
          </p>
          <p>
            <strong>Minderjährige</strong>
            <br />
            Der Schutz der Daten von Kindern ist insbesondere im Online-Bereich sehr wichtig. Die Webseite ist nicht für Kinder konzipiert und richtet sich nicht an diese. Die Nutzung unserer Services durch Minderjährige ist nur mit der vorherigen Einwilligung oder Autorisierung von einem Elternteil oder Erziehungsberechtigten zulässig. Wir erfassen personenbezogene Daten von Minderjährigen nicht wissentlich. Wenn ein Elternteil oder Erziehungsberechtigter Kenntnis davon erlangt, dass sein oder ihr Kind uns personenbezogene Daten ohne deren Einwilligung bereitgestellt hat, kann er/sie sich unter <a href="mailto:info@eventboxde.com">info@eventboxde.com</a> an uns wenden.
          </p>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  }
};
</script>